export const validationMessages = {
  ENG: {
    answerRequired: 'This answer is required',
    allZerosNotAllowed: 'All zeros not allowed',
    mustContain9Digits: 'The number must contain 9 digits',
    allAstericksNotAllowed: 'The answer cannot contain all asterisks',
    astericksNotAllowedInFirstPosition: 'An asterisk is not allowed in the first position',
  },
  ESP: {
    answerRequired: 'Esta respuesta es requerida',
    allZerosNotAllowed: 'Todos los ceros no admitidas',
    mustContain9Digits: 'El número debe contener 9 dígitos',
    allAstericksNotAllowed: 'La respuesta no puede contener solo los asteriscos',
    astericksNotAllowedInFirstPosition: 'No se permite un asterisco en la primera posición',
  },
};
