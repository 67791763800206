import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import './App.scss';

import SecureInput from '../Inputs/SecureInput';
import SecureDate from '../Inputs/SecureDate';
import SecureSsn from '../Inputs/SecureSsn';
import SecureDriverLicenseNumber from '../Inputs/SecureDriverLicenseNumber';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={SecureSsn} />
      <Route path="/secure-input" component={SecureInput} />
      <Route path="/secure-date" component={SecureDate} />
      <Route path="/secure-ssn" component={SecureSsn} />
      <Route path="/secure-driver-license-number" component={SecureDriverLicenseNumber} />
      <Route component={SecureInput} />
    </Switch>
  </BrowserRouter>
);

export default App;
