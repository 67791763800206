import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import submittingStatuses from '../../constants/submittingStatuses';
import { validationMessages } from './validationMessages';
import commands from '../../constants/commands';

const STUDENT_APP_URL = `${process.env.REACT_APP_SERVER_URL}/applications`;
const PARENT_APP_URL = `${process.env.REACT_APP_SERVER_URL}/parents-applications`;
const { SECURE_SSN_INIT, CHANGE_LANGUAGE, SUBMIT, FOCUS_ON_IT } = commands;

class SecureSsn extends Component {
  constructor(props) {
    super(props);
    this.iframeData = null;
    this.state = {
      placeHolderValue: '',
      ssnEntered: '',
      ssnLastDigits: '',
      required: false,
      valid: true,
      touched: false,
      language: 'ENG',
    };
  }

  // Input reference to call DOM level fucntions.
  inputRef = React.createRef();

  componentDidMount() {
    window.addEventListener('message', this.receiveMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage, false);
  }

  // Get existing SSN infomrtion from frank-api
  fetchSsn = async () => {
    const {
      applicationId,
      applicationType,
      ssnLastDigitsProp,
      token,
    } = this.iframeData;
    if (!applicationId) return;
    const options = {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
    };
    const response = (applicationType === 'PARENT')
      ? axios.post(`${PARENT_APP_URL}/validate`, { inviteParentToken: token }, options)
      : axios.get(`${STUDENT_APP_URL}/${applicationId}`, options);
    const { data: appData } = await response;
    const ssnLastDigits = appData[ssnLastDigitsProp] || null;
    if (ssnLastDigits) {
      this.sendMessage({ ssnLastDigits });
      this.setState({
        ssnLastDigits,
        placeHolderValue: `*** - ** - ${ssnLastDigits}`,
      });
    }
  };

  // Save ssn after it passes validation.
  saveSsn = async (ssn) => {
    const {
      applicationId,
      applicationType,
      ssnProp,
      ssnLastDigitsProp,
      encryptedPropName,
      token,
      required,
    } = this.iframeData;

    if (!applicationId || (!ssn && required)) {
      this.sendMessage({ submittingState: submittingStatuses.FAILURE });
    }

    const appUrl = (applicationType === 'PARENT') ? (`${PARENT_APP_URL}/${applicationId}`) : (`${STUDENT_APP_URL}/${applicationId}`);
    const data = { [ssnProp]: ssn };
    if (ssn === '') {
      data[ssnProp] = null;
      data[ssnLastDigitsProp] = null;
      if (encryptedPropName) data[encryptedPropName] = null;
    }

    const { data: appData } = await axios.put(appUrl, data, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
    });

    const {
      [ssnLastDigitsProp]: ssnLastDigits,
      [encryptedPropName]: encryptedValue,
    } = appData;
    if (ssnLastDigits || ssnLastDigits === null) {
      this.setState({ ssnLastDigits });
      this.sendMessage({
        ssnLastDigits,
        encryptedValue,
        submittingState: submittingStatuses.SUCCESS,
      });
    } else {
      this.sendMessage({
        submittingState: submittingStatuses.FAILURE,
      });
    }
  };

  // On new message receive from parent application.
  receiveMessage = (event) => {
    // This check is require to make communication secure.
    if (event.origin !== process.env.REACT_APP_FAFSA_URL) {
      console.warn("Secure inputs is not configured for this application host.");
      return;
    }
    const { command } = event.data;

    if (command === SECURE_SSN_INIT) {
      this.onInitMessageReceived(event);
    } else if (command === CHANGE_LANGUAGE) {
      this.onChangelanguageMessageReceived(event);
    } else if (command === SUBMIT) {
      this.onSubmitMessageReceived(event);
    } else if (command === FOCUS_ON_IT) {
      this.onFoucsReceived();
    }
  };

  onInitMessageReceived = (event) => {
    const { language } = this.state;
    const { data: iframeData } = event;
    const { required, language: lang } = iframeData;
    this.iframeData = iframeData;
    const stateUpdate = { required };
    if (lang && language !== lang) stateUpdate.language = lang;

    this.setState(stateUpdate);
    this.fetchSsn();
  }

  onChangelanguageMessageReceived = (event) => {
    const { language: lang } = this.state;
    const { language } = event.data;
    if (language && language !== lang) this.setState({ language });
  }

  onSubmitMessageReceived = () => {
    const {
      ssnEntered, ssnLastDigits, required, touched,
    } = this.state;

    if (!touched && (ssnLastDigits || !required)) {
      return this.sendMessage({ ssnLastDigits, submittingState: submittingStatuses.SUCCESS });
    }
    if (this.isValid(ssnEntered)) {
      return this.saveSsn(ssnEntered);
    }
    this.setState({ valid: false });
    return this.sendMessage({ submittingState: submittingStatuses.FAILURE });
  }

  onFoucsReceived = () => {
    if(this.inputRef && this.inputRef.current && this.inputRef.current.focus) {
      this.inputRef.current.focus();
    }
  }

  // Send message to parent window.
  sendMessage = (message) => {
    const { iframeId } = this.iframeData;
    window.parent.postMessage({ iframeId, ...message }, process.env.REACT_APP_FAFSA_URL);
  }

  isValid = (val) => {
    const { ssnLastDigits, required, touched } = this.state;
    const ssnProp = this.iframeData && this.iframeData.ssnProp;
    const isTouchedAndEmpty = touched && !val;
    const isNotTouchedAndNotSaved = !touched && !ssnLastDigits;

    return !(
      (required && (isTouchedAndEmpty || isNotTouchedAndNotSaved))
      || (ssnProp === 'studentSsn' && val === '000000000')
      || (val && val.length !== 9)
    );
  }

  validationMessage = () => {
    const {
      ssnEntered, ssnLastDigits, required, language, touched,
    } = this.state;
    const ssnProp = this.iframeData && this.iframeData.ssnProp;

    const {
      answerRequired, allZerosNotAllowed, mustContain9Digits,
    } = validationMessages[language];

    const isSsnEmpty = !ssnEntered && !ssnLastDigits;
    const isSsnErased = touched && !ssnEntered;

    if (required && (isSsnEmpty || isSsnErased)) {
      return answerRequired;
    }
    if (ssnProp === 'studentSsn' && ssnEntered === '000000000') {
      return allZerosNotAllowed;
    }
    if (ssnEntered && ssnEntered.length !== 9) {
      return mustContain9Digits;
    }
    return '';
  }

  // Detect ssn value change
  ssnValueChange = ({ value: ssnEntered }) => {
    const newState = { ssnEntered };
    if (this.isValid(ssnEntered)) {
      newState.valid = true;
    }
    this.setState(newState);
    // information is necessary for parent component to make decision about opening a modal window
    let { ssnLastDigits } = this.state;
    if (ssnEntered) ssnLastDigits = ssnEntered.substring(5);
    this.sendMessage({ ssnLastDigits });
  };

  blurHandle = () => {
    const { ssnEntered } = this.state;
    const valid = this.isValid(ssnEntered);
    this.setState({ valid });
  };

  focusHandle = () => {
    this.setState({
      touched: true,
      placeHolderValue: '',
    });
  }

  render() {
    const {
      touched, valid, language, placeHolderValue,
    } = this.state;

    const validationMessage = (touched || !valid) && this.validationMessage();
    let ssnHolderClass = 'ssn-holder';
    let ssnMessageClass = 'validation-message';
    if (!valid) {
      ssnHolderClass += ' invalid';
      ssnMessageClass += ' invalid';
    }

    const ariaLabel = (
      this.iframeData && this.iframeData.ariaLabels && this.iframeData.ariaLabels[language]
    ) || 'Social Security number';

    return (
      <div className="ssn">
        <div className={ssnHolderClass}>
          <NumberFormat
            getInputRef={this.inputRef}
            aria-label={`${ariaLabel} ${validationMessage}`}
            format="### - ## - ####"
            placeholder={placeHolderValue}
            mask="_"
            onBlur={this.blurHandle}
            onValueChange={this.ssnValueChange}
            onFocus={this.focusHandle}
          />
        </div>
        <div className={ssnMessageClass}>{validationMessage}</div>
      </div>
    );
  }
}

export default SecureSsn;
