import { validationMessages } from '../validationMessages';

export const driverLicenseNumberRegexp = /^[A-Za-z0-9-* ]*$/;

export const validate = ({ value, isRequired }) => {
  const trimmedValue = value.trim();

  if (!trimmedValue) {
    if (isRequired) {
      return false;
    }
  } else {
    const isAsterisk = (val) => val === '*';
    const hasAllAsterisks = trimmedValue.split('').every(isAsterisk);

    if (hasAllAsterisks) {
      return false;
    }

    if (isAsterisk(trimmedValue[0])) {
      return false;
    }
  }

  return true;
};

export const getValidationMessage = ({ value, isRequired, language }) => {
  const trimmedValue = value.trim();

  const {
    answerRequired, allAstericksNotAllowed, astericksNotAllowedInFirstPosition,
  } = validationMessages[language];

  if (!trimmedValue) {
    if (isRequired) {
      return answerRequired;
    }
  } else {
    const isAsterisk = (val) => val === '*';
    const hasAllAsterisks = trimmedValue.split('').every(isAsterisk);

    if (hasAllAsterisks) {
      return allAstericksNotAllowed;
    }

    if (isAsterisk(trimmedValue[0])) {
      return astericksNotAllowedInFirstPosition;
    }
  }

  return true;
};
