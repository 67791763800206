import React, { useEffect, useState } from 'react';
import { sendValue } from '../../shared/communicate';

const SecureInput = () => {
  const [inputVal, setInputVal] = useState('123456789');
  const handleChange = (e) => {
    sendValue(e.target.value);
    setInputVal(e.target.value);
  };
  const messageReceived = (message) => {
    console.info(message);
  };

  useEffect(() => {
    window.addEventListener('message', messageReceived);
  }, []);

  return (
    <input type="text" onChange={handleChange} placeholder="Type text here" value={inputVal} />
  );
};

export default SecureInput;
