import React from 'react';
import axios from 'axios';

import submittingStatuses from '../../../constants/submittingStatuses';
import commands from '../../../constants/commands';
import { validate, getValidationMessage, driverLicenseNumberRegexp } from './helpers';
import { STUDENT_APP_URL, PARENT_APP_URL } from './constants';

const { CHANGE_LANGUAGE, SUBMIT, FOCUS_ON_IT } = commands;

class SecureDriverLicense extends React.Component {
  constructor(props) {
    super(props);
    this.iframeData = {};
    this.state = {
      isValid: true,
      isFocused: false,
      language: 'ENG',
      driverLicenseValue: '',
      encryptedValue: null,
      isRequired: false,
    };
  }

  // Input reference to call DOM level fucntions.
  inputRef = React.createRef();

  componentDidMount() {
    window.addEventListener('message', this.receiveMessage, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const { driverLicenseValue, isRequired } = this.state;
    const {
      driverLicenseValue: prevDriverLicenseValue,
      isRequired: prevIsRequired,
    } = prevState;
    if (driverLicenseValue !== prevDriverLicenseValue || prevIsRequired !== isRequired) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isValid: validate({ value: driverLicenseValue, isRequired }) });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage, false);
  }

  sendMessage = (message) => {
    const { iframeId } = this.iframeData;

    window.parent.postMessage({ iframeId, ...message }, process.env.REACT_APP_FAFSA_URL);
  };

  fetchDriverLicenseNumber = async () => {
    const {
      applicationId,
      applicationType,
      encryptedProp,
      token,
    } = this.iframeData;

    if (!applicationId) return;

    const options = {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
    };

    const response = (applicationType === 'PARENT')
      ? axios.post(`${PARENT_APP_URL}/validate`, { inviteParentToken: token }, options)
      : axios.get(`${STUDENT_APP_URL}/${applicationId}`, options);

    const { data: appData } = await response;

    const encryptedDriversLicense = appData[encryptedProp];

    if (encryptedDriversLicense) {
      this.sendMessage({ encryptedDriversLicense });

      this.setState({ encryptedValue: encryptedDriversLicense });
    }
  }

  saveDriverLicenseNumber = async (value) => {
    const {
      applicationId,
      applicationType,
      encryptedProp,
      token,
      prop,
    } = this.iframeData;

    if (!applicationId) return;

    const appUrl = (applicationType === 'PARENT') ? (`${PARENT_APP_URL}/${applicationId}`) : (`${STUDENT_APP_URL}/${applicationId}`);

    const data = {
      [prop]: value,
    };

    try {
      const {
        data: { [encryptedProp]: encryptedDriversLicense },
      } = await axios.put(appUrl, data, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'json',
      });

      if (encryptedDriversLicense) {
        this.sendMessage({
          encryptedDriversLicense,
          submittingState: submittingStatuses.SUCCESS,
        });

        this.setState({ encryptedValue: encryptedDriversLicense });
      } else {
        this.sendMessage({ submittingState: submittingStatuses.FAILURE });
      }
    } catch (err) {
      console.error(err);

      this.sendMessage({ submittingState: submittingStatuses.FAILURE });
    }
  }

  onSubmitMessageReceived = () => {
    const {
      driverLicenseValue, encryptedValue, isRequired, isValid,
    } = this.state;
    const trimmedValue = driverLicenseValue.trim();

    if (!trimmedValue && (encryptedValue || !isRequired)) {
      return this.sendMessage({
        encryptedDriversLicense: encryptedValue,
        submittingState: submittingStatuses.SUCCESS,
      });
    }

    if (isValid) {
      return this.saveDriverLicenseNumber(trimmedValue);
    }

    return this.sendMessage({ submittingState: submittingStatuses.FAILURE });
  }

  onFoucsReceived = () => {
    if(this.inputRef && this.inputRef.current && this.inputRef.current.focus) {
      this.inputRef.current.focus();
    }
  }

  receiveMessage = (event) => {
    const { language } = this.state;
    if (event.origin === process.env.REACT_APP_FAFSA_URL) {
      const { command, required, language: lang } = event.data;
      if (command === SUBMIT) {
        this.onSubmitMessageReceived();
      } else if (command === CHANGE_LANGUAGE) {
        if (lang && language !== lang) {
          this.setState({ language: lang });
        }
      } else if (command === FOCUS_ON_IT) {
        this.onFoucsReceived();
      } else {
        this.iframeData = { ...event.data };
        this.setState({ isRequired: required });
        if (lang && language !== lang) {
          this.setState({ language: lang });
        }

        this.fetchDriverLicenseNumber();
      }
    }
  }

  handleChange = ({ target: { value } }) => {
    if (driverLicenseNumberRegexp.test(value)) {
      this.setState({ driverLicenseValue: value });
    }
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
  };

  handleFocus = () => {
    this.setState({ isFocused: true });
  };

  render() {
    const { ariaLabels } = this.iframeData;
    const {
      isValid, isFocused, language, driverLicenseValue, encryptedValue, isRequired,
    } = this.state;
    let validationMessageClassName = 'validation-message';
    let holderClassName = 'ssn-holder';

    if (!isValid && !isFocused) {
      holderClassName += ' invalid';
      validationMessageClassName += ' invalid';
    }

    const placeholder = (encryptedValue && !isFocused) ? '**********' : '';

    const validationMessage = getValidationMessage({
      value: driverLicenseValue, isRequired, language,
    });

    return (
      <div className="ssn">
        <div className={holderClassName}>
          <input
            ref={this.inputRef}
            aria-label={
              (ariaLabels && ariaLabels[language]) || 'Driver\'s license number'
            }
            value={driverLicenseValue}
            placeholder={placeholder}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onChange={this.handleChange}
          />
        </div>
        <div className={validationMessageClassName}>{validationMessage}</div>
      </div>
    );
  }
}

export default SecureDriverLicense;
